<template>
  <div>
    <div :class="btnLabel ? 'jumbotron-container' : 'no-btn-container'">
      <div class="jumbotron-image">
        <img :src="require('../../assets/' + illustration + '.png')" alt=""/>
      </div>
      <div class="jumbotron-content">
        <div class="jumbotron-title">{{ title }}</div>
        <p v-if="desc" class="jumbotron-description">{{ desc }}</p>
        <div v-if="btnLabel" class="btn-container">
          <nitrozen-button
            v-if="btnLabel"
            :theme="'secondary'"
            v-flatBtn
            @click.stop="btnClick"
            :disabled="btnDisabled"
            >{{ btnLabel }}</nitrozen-button
          >
          <nitrozen-button
            v-if="secondaryBtnLabel"
            :theme="'secondary'"
            v-flatBtn
            @click.stop="secondaryBtnClick"
            >{{ secondaryBtnLabel }}</nitrozen-button
          >
          <slot />
        </div>
      </div>
    </div>
    <adm-page-header
      :title="title"
      @backClick="isFPApp ? onAppBack() : () => {}"
      :showBackButton="isFPApp"
      class="mobile-page-header"
    >
      <template slot="page-slot-mobile-add">
        <adm-inline-svg
          v-if="btnLabel"
          @click.stop.native="btnClick"
          src="add-icon"
        ></adm-inline-svg>
      </template>
    </adm-page-header>
  </div>
</template>

<script>
import { NitrozenButton, flatBtn } from "@gofynd/nitrozen-vue";
import admpageheader from "./adm-page-header.vue";
import adminlinesvg from "./adm-inline-svg.vue";
import { detectFPApp, detectMobileWidth } from "@/helper/utils";
import { goBack } from "@/helper/actions";

export default {
  name: "adm-jumbotron",
  components: {
    "adm-page-header": admpageheader,
    "adm-inline-svg": adminlinesvg,
    "nitrozen-button": NitrozenButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    btnLabel: {
      type: String,
    },
    secondaryBtnLabel: {
      type: String,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    illustration: {
      type: String,
      default: "illustration",
    },
  },
  directives: {
    flatBtn,
  },
  data: function () {
    return {};
  },
  computed: {
    isFPApp() {
      return detectFPApp()
    },
  },
  mounted() {
    //will add later
  },
  methods: {
    detectMobileWidth,
    detectFPApp,
    btnClick() {
      this.$emit("btnClick");
    },
    secondaryBtnClick() {
      this.$emit("secondaryBtnClick");
    },
    onAppBack() {
      goBack();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/theme.less";
@import "../../less/media.less";
@import "../../less/text.less";
@import "../../less/color.less";
@import "../../less/page-header.less";
.jumbotron-container {
  font-family: Inter, sans-serif;
  flex: 1;
  padding: 20px 24px;
  border: 1px solid @Iron;
  border-radius: 3px;
  display: flex;
  background-color: @White;
  min-height: 200px;
  @media @mobile {
    display: none;
  }
  .jumbotron-content {
    flex: 0.65;
    .jumbotron-title {
      padding: 16px 0px 0px;
      color: @Mako;
      font-weight: bold;
      font-size: 24px;
      // line-height: 48px;
      text-align: left;
    }
    .jumbotron-description {
      font-size: 16px;
      line-height: 26px;
      color: @Mako;
    }
    // .n-button-secondary.n-flat-button.n-flat-button-secondary {
    //     margin-top: 24px;
    // }
  }
  .jumbotron-image {
    flex: 0.35;
    img {
      // height: 221px;
      width: 400px;
      float: right;
      height: 175px;
    }
  }
  .btn-container {
    white-space: nowrap;
    display: flex;
    margin-top: 24px;
    button:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.no-btn-container {
  font-family: Inter, sans-serif;
  flex: 1;
  margin: 24px;
  padding: 20px 24px;
  // border: 1px solid @Iron;
  border-radius: 3px;
  display: flex;
  background-color: @White;
  // height: 108px;
  @media @mobile {
    display: none;
  }
  .jumbotron-content {
    .jumbotron-title {
      padding: 16px 0px 0px;
      // line-height: 48px;
      text-align: left;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* Text/primary */

      color: #41434c;
    }
    .jumbotron-description {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height, or 121% */

      /* Text/secondary */

      color: #666666;
    }
  }
  .jumbotron-image {
    img {
      // height: 221px;
      // width: 400px;
      // float: right;
      height: 90px;
      margin-right: 10px;
    }
  }
}
.mobile-page-header {
  margin: -24px -24px 12px -24px;
  display: none;
  @media @mobile {
    display: block;
  }
}
</style>
