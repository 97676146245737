<template>
  <div>
    <ext-bridge :isHintAdded="false"></ext-bridge>
    <div v-if="!inProgress">
      <adm-jumbotron
        class="jumbotron-h"
        :title="`${this.marketplaceName} Marketplace`"
        :desc="`A one-stop solution that helps you manage products, selling locations, and inventory together for your business on ${this.marketplaceName}`"
        :illustration="`${this.marketplaceName}_logo`"
      >
      </adm-jumbotron>
        <menu-items ref="menu"> </menu-items>
    </div>
    <div v-else>
      <loader class="loading" v-if="inProgess"></loader>
    </div>
  </div>
</template>

<script>
import MainService from "@/services/main.service";
import isEmpty from "lodash/isEmpty";
import loader from "@/components/common/adm-loader";
import { setCompany } from "@/helper/utils";
import menu from "./menu.vue";
import extBridge from "./ext-bridge.vue";
import { MARKETPLACE_NAME } from "@/helper/constant.js";

import admJumbotron from "@/components/common/adm-jumbotron.vue";


let MENU_OPTIONS = [];

export default {
  name: "main",
  components: {
    loader,
    "adm-jumbotron": admJumbotron,
    "menu-items": menu,
    "ext-bridge": extBridge,
  },
  props: {
    showSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOptions: MENU_OPTIONS,
      inProgress: true,
      credEmpty: false,
      creds: {},
      marketplaceName : MARKETPLACE_NAME
    };
  },
  mounted() {
    this.inProgress = true;
    this.showSettings = Number(
      (this.$route && this.$route.query && this.$route.query.settings) || false
    );
    console.log("remove this log later");
    this.initializeMainScreen();
  },
  methods: {
    initializeMainScreen() {
      MainService.getCompanyConfig()
        .then(({ data }) => {
          let existingCreds = data || {};
          console.log("data is", data, existingCreds);
          if (!isEmpty(existingCreds)) {
            console.log("went into it");
            this.credEmpty = false;
          } else {
            this.credEmpty = true;
          }
          this.creds = existingCreds;

          this.$forceUpdate();
        })
        .catch((err) => {
          console.log("err", err);
          this.$snackbar.global.showError("Failed to get company creds");
          this.credEmpty = true;
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
  },
  isEmpty(obj) {
    return isEmpty(obj);
  },
  beforeRouteEnter: (to, from, next) => {
    console.log("to", to);
    console.log("from", from);
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
.jumbotron-h {
  :deep(.no-btn-container) {
    border-radius: 12px;
  }
}
.mkp-features {
  margin-top: 36px;
  width: 95%;
}
.mkp-features-items {
  margin-left: 24px;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-gap: 24px;
  @media @mobile {
    grid-template-columns: 100%;
  }
}
.mkp-features-title {
  color: #41434c;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.mkp-features-desc {
  color: #41434c;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  height: 90px;
  @media @mobile {
    height: 50px;
  }
}
.mkp-features-route {
  padding: 16px;
  align-items: center;
  background: #fff;
  border-radius: 4px;
}
.mkp-features-link {
  padding-top: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: royalblue;
}
.settings {
  margin: 24px;

}
</style>
