import { Extension } from '@gofynd/fdk-extension-bridge-javascript'

import { API_KEY } from './helper/constant.js';
import root from "window-or-global";
const ENVS = ['fyndx1', 'fynd', 'fyndx5']
let env = root.location.hostname.split('.')[2];
// console.log(root.location.hostname.split('.'))
if (!ENVS.includes(env)) {
    env = ENVS[0]
}
console.log("api key is", API_KEY[env].api_key);
let ext = new Extension({
    apiKey: API_KEY[env].api_key
    // apiKey: '60be66a2d4cbbf1fd0a67c8c'
});

export default ext
