<template>
  <div>
    <div class="announcement" v-if="!isCompanyActive">
      <div class="svg-icon">
        <inline-svg :src="'warning_blue'" class="feature-icon warning-blue"></inline-svg>
      </div>
      <div class="announcement-text">
        The extension is disabled for this company. To activate it, go to
        top-right context > Settings > turn ON 'Active' toggle.
      </div>
    </div>
    <!-- added div for checked value -->
    <div v-else>
      <div v-if="inProgress">
        <loader class="loading"></loader>
      </div>
      <div v-else>
        <div class="suggestion-tag" :class="getSuggestionTextColor()" v-if="showSuggestionBlock">
          <inline-svg :src="getSuggestionIcon()" class="feature-icon warning-blue"></inline-svg>
          &nbsp;&nbsp;
          <p class="suggestion-text" v-if="fpSyncStatus == ''">
            Map your
            <strong class="suggestion-bold" @click="navigateToMenu('/products')">products</strong>
            and
            <strong class="suggestion-bold" @click="navigateToMenu('/locations')">selling locations</strong> to sync inventory with {{ this.marketplaceName }} marketplace
          </p>
          <p class="suggestion-text" v-if="fpSyncStatus == 'COMPLETED'">
            The extension setup is complete. You can now map selling locations and products to get started.
          </p>
          <p class="suggestion-text" v-if="fpSyncStatus == 'FAILED'">
            This extension setup is incomplete. Please retry the sync to start using the extension. Please contact administrator if further assistance required.
          </p>
          <p class="suggestion-text" v-if="fpSyncStatus == 'ONGOING'">
            This extension is being setup. Please wait until the Location and Products sync completes.
          </p>
          <p class="suggestion-text" v-if="fpSyncStatus == 'PARTIAL_SUCCESS'">
            This extension setup is incomplete. Please retry the sync to start using the extension.  Please contact administrator if further assistance required.
          </p>
          <div class="cancel-icon" v-if="!isFirstTimeOnBoard" @click="() => (showSuggestionBlock = false)">
            <inline-svg :src="'cancel'"></inline-svg>
          </div>
        </div>
        <div class="text-headings">
          <div class="features-text">Features</div>
        </div>
        <div class="main-page">
          <div class="left-section" :class="{ isDisabled: disabled }">
            <div class="menus-container">
              <div class="menus-container-cards">
                <div v-for="menu in menuOptions" :key="menu.title">
                  <e-feature-menu
                    :title="menu.title"
                    :desc="menu.desc"
                    :icon="menu.icon"
                    :isLive="menu.isLive"
                    :liveTitle="menu.liveTitle"
                    :liveCount="menu.liveCount"
                    :name="menu.link"
                    :link="menu.link"
                    :isDisabled="menu.disabled"
                    @onNavigate="navigateToMenu($event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSyncStatusOn" class="right-section">
            <div class="fpSync-status">
              <div class="flex">
                <radial-progress-bar :innerStrokeColor="innerStrokeColor" :stopColor="stop_color"
                  :startColor="start_color" :innerStrokeWidth="4" :strokeWidth="4" :diameter="50"
                  :completed-steps="totalJobCompletionPercentage" :total-steps="totalSteps">
                  <p v-if="fpSyncStatus == 'ONGOING'">
                    {{ totalJobCompletionPercentage }}%
                  </p>
                  <div class="icon" v-if="
                    fpSyncStatus == 'COMPLETED' ||
                    fpSyncStatus == 'FAILED' ||
                    fpSyncStatus == 'PARTIAL_SUCCESS'
                  ">
                    <img v-if="fpSyncStatus == 'COMPLETED'" class="syncDone" alt=""
                      src="./../assets/svgs/sync_tick.svg" />
                    <img class="syncError" v-if="fpSyncStatus == 'FAILED'" alt=""
                      src="./../assets/svgs/error_sync.svg" />
                    <img class="syncError" v-if="fpSyncStatus == 'PARTIAL_SUCCESS'" alt=""
                      src="./../assets/svgs/pending.svg" />
                  </div>
                </radial-progress-bar>
                <div class="status">
                  <p>Location & Product Sync</p>
                  <div class="fpsync-status-text">{{ this.getJobStatusMessage(fpSyncStatus) }}</div>
                </div>
              </div>
              <div class="content">
                <p>
                  Location & product sync from catalog to the marketplace extension
                </p>
              </div>
              <div class="sync-date">
                {{ syncStartDate }}
              </div>
              <div class="action-buttons" v-if="fpSyncStatus == 'FAILED' || fpSyncStatus == 'PARTIAL_SUCCESS'"
                @click="retryFPSync()">
                <img alt="" src="./../assets/svgs/retry.svg" />
                <p class="action-text">Retry</p>
              </div>
              <div class="action-buttons" v-if="fpSyncStatus == 'COMPLETED'" @click="closeFPSyncStatus()">
                <img alt="" src="./../assets/svgs/cancel_blue.svg" />
                <p class="action-text">Close</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
.loading {
  z-index: 1000;
}

.syncError {
  display: flex;
  align-items: center;
  justify-content: center;
}

.syncDone {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;

  .radial-progress-inner {
    p {
      font-size: 12px;
      margin-top: 3px;
      margin-left: 3px;
    }
  }
}

.sync-date {
  width: 198px;
  height: 17px;
  left: 1171px;
  top: 445px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: #666666;
  margin-top: 6px
}

.status {
  margin-left: 10px;

  .fpsync-status-text {
    font-weight: 500;
  }

  p {
    font-weight: 400;
    color: #666;
    font-size: 12px;
  }
}

.main-page {
  display: flex;

  .left-section {
    flex: 7 0 0;
  }

  .right-section {
    flex: 2.5 0 0;

    .fpSync-status {
      font-family: Inter, sans-serif;
      font-style: normal;
      width: 75%;
      height: fit-content;
      border: 1px solid #eae6e6;
      position: relative;
      margin-top: 23px;
      border-radius: 12px;
      padding: 20px 20px !important;

      .content {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        border-top: 1px solid #f2f2f2;
        margin-top: 10px;

        p {
          margin-top: 10px;
        }
      }
    }

    .learning-links {
      width: 270px;
      height: 400px;
      border: 1px solid #eae6e6;
      ;
      margin-top: 23px;
      border-radius: 12px;
    }
  }
}

.suggestion-tag {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin: 24px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  .warning-blue {
    margin-right: 6px;
    display: inline;
    margin-top: 6px;
  }

  :deep(.inline-svg) {
    display: inline;
  }

  .suggestion-text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* Text/secondary */

    color: black;

    .suggestion-bold {
      color: #2e31be;
      cursor: pointer;
    }
  }
  .cancel-icon {
    display: inline;
    // float: right;
    cursor: pointer;
    margin-left: auto;
  }
}
.action-buttons {
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  color: #2e31be;
  align-items: center
}

.action-text {
  font-size: 12px;
  font-weight: 700;
  margin: 5px;
}

.COMPLETED {
  background: #e3f2e9 !important;
  border: 1px solid #0A5F23 !important;
}

.FAILED {
  background: #ffe7e7 !important;
  border: 1px solid #CD0909 !important;
}

.ONGOING {
  background: #e7eeff !important;
  border: 1px solid #2E31BE !important;
}

.PARTIAL_SUCCESS {
  background: #ffe7e7 !important;
  border: 1px solid #CD0909 !important;
}

.STOPPED {
  background: #FFF5D6 !important;
  border: 1px solid #F5A300 !important;
}

.announcement {
  display: flex;
  justify-content: left;
  background: #ebedfb;
  padding-left: 9px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
}

.announcement-text {
  height: 48px;
  line-height: 50px;
  padding-left: 10px;
}
.text-headings {
  margin-left: 24px;
  display: flex;
  .features-text {
    width: 76%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #41434c;
  }
  .learning-links-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #41434c;
  }
}

.menus-container {
  display: flex;
  .menus-container-cards {
    width: 100%;
    // width: 1140px;
    display: flex;
    flex-wrap: wrap;
    margin: 12px;
    .menu-card {
      margin: 12px;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      box-sizing: border-box;
      border-radius: 12px;
      width: 267px;
      height: 240px;
      display: flex;
      flex-direction: column;
      .menu-icon {
        :deep(.inline-svg) {
          justify-content: left;
          margin-bottom: 24px;
        }
      }
      .menu-title {
        margin: 6px 0px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        /* identical to box height, or 140% */

        color: #41434c;
      }
      .menu-desc {
        margin: 0px 0px 12px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* or 150% */

        /* Gray 3 */

        color: #828282;
      }
      .menu-live {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        // margin-top: 24px;
        .menu-live-title {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          /* identical to box height, or 150% */

          /* Gray 3 */

          color: #828282;
        }
        .menu-live-count {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height, or 120% */

          /* Gray 1 */

          color: #333333;
        }
        .menu-live-next-btn {
          float: right;
          cursor: pointer;
        }
      }
    }
  }
  .menus-container-learning {
    background: #ffffff;
    margin: 24px 0px;
    border-radius: 12px;
    border: 1px solid #f3f3f3;
    align-items: baseline;
    // height: fit-content;
    width: 267px;
    height: 240px;
    .menu-learning {
      display: flex;
      text-decoration: underline;
      .menu-learning-image {
        margin: 12px;
        width: 70px;
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
      }
      .menu-learning-text {
        margin: 12px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        /* or 142% */

        /* Text/primary */

        color: #41434c;
      }
    }
  }
}
</style>

<script>
import MainService from "@/services/main.service";
import loader from "@/components/common/adm-loader";
import urlJoin from "url-join";
import { getCompanyBasePath } from "@/helper/utils.js";
import InlineSvg from "@/components/common/inline-svg";
import { ExtensionFeatureMenu } from "marketplace-utilities/web";
import RadialProgressBar from "vue-radial-progress";
import moment from "moment";
import { MARKETPLACE_NAME } from "@/helper/constant.js";

const FootContent = [
  {
    title: "Manage Products",
    desc: `Sync fynd platform products to ${MARKETPLACE_NAME}`,
    icon: "product_store",
  },
  {
    title: "Sync Selling Locations",
    desc: `One click syncing of selling locations to ${MARKETPLACE_NAME}`,
    icon: "store_deployment",
  },
  {
    title: "Analytics & Reports",
    desc: "Tools to track your marketplace success",
  },
];

const MENU_OPTIONS = [
  {
    title: "Products",
    desc: "Add SKU identifier and make your products LIVE",
    icon: "product_store",
    isLive: true,
    liveTitle: "No. of LIVE products",
    liveCount: "",
    name: "products",
    link: "/products",
    disabled: false
  },
  {
    title: "Selling Locations",
    desc: `Link selling locations on FP with those in your ${MARKETPLACE_NAME} account`,
    icon: "store_deployment",
    isLive: true,
    liveTitle: "Selling location mapped",
    liveCount: "",
    link: "/locations",
    name: "locations",
    disabled: false
  },
  {
    title: "Manual Sync",
    desc: `Keep the quantity updated between FP and ${MARKETPLACE_NAME}`,
    icon: "trigger",
    // isLive: true,
    link: "/manual-trigger",
    name: "manual-trigger",
    disabled: false
  },
];

export default {
  name: "marketplace-config-form",
  components: {
    "inline-svg": InlineSvg,
    "e-feature-menu": ExtensionFeatureMenu,
    loader,
    RadialProgressBar
  },
  props: {
  },
  data: function () {
    return {
      marketplaceName : MARKETPLACE_NAME,
      showSuggestionBlock: true,
      menuOptions: [],
      syncDefault: {},
      isCompanyActive : false,
      configError: "",
      inProgress: false,
      cleanState: {},
      configuration: {},
      visibility: {
        makeVisible: "password-eye",
        makeInvisible: "password-eye-cancel",
      },
      currentVisibility: "password-eye",
      iframeLoad: false,
      footContent: FootContent,
      disabled: false,
      fpSyncList: [
        {
          title: "Locations",
          description: "Sync locations from Fynd Platfrom.",
          isSelected: false,
          value: "locations",
          lastJobStatus: "",
          lastRunAt: "",
          jobCompletionPercentage: 0
        },
        {
          title: "Products",
          description: "Sync products from Fynd Platfrom.",
          isSelected: false,
          value: "products",
          lastJobStatus: "",
          lastRunAt: "",
          jobCompletionPercentage: 0
        }
      ],
      totalJobCompletionPercentage: 0,
      totalSteps: 100,
      isDisabled: true,
      fpSyncStatus: "",
      start_color: "#27ae60",
      stop_color: "#27ae60",
      isSyncStatusOn: false,
      innerStrokeColor: "#f2f2f2",
      isFirstTimeOnBoard: false,
      syncStartDate: ""
    };
  },
  mounted() {
    this.inProgress = true;
    this.menuOptions = MENU_OPTIONS;
    this.initializeMenuScreen();
    this.checkCompanyStatus();
    this.checkIfFirstTimeOnboard();
    this.setLocalSession();
  },
  methods: {
    setLocalSession() {
      if (localStorage.getItem("isSyncStatusOn") === "yes") {
        this.isSyncStatusOn = true;
      }
      if (localStorage.getItem("fpSyncStatus") === "COMPLETED") {
        this.fpSyncStatus = "COMPLETED";
      }
      if (localStorage.getItem("totalJobCompletionPercentage") === "100") {
        this.totalJobCompletionPercentage = "100";
      }
      if (localStorage.getItem("syncStartDate") != "") {
        this.syncStartDate = localStorage.getItem("syncStartDate");
      }
    },
    checkCompanyStatus() {
      return MainService.getCompanyStatus().then(({ data }) => {
        this.isCompanyActive = data.is_active;
      });
    },
    getSuggestionTextColor() {
      return this.fpSyncStatus
    },
    getSuggestionIcon() {
      let iconMappings = {
        "COMPLETED": "success_tick",
        "PARTIAL_SUCCESS": "warning_red",
        "FAILED": "warning_red",
        "ONGOING": "warning_blue",
      }
      return iconMappings[this.fpSyncStatus] || "warning_blue"
    },
    getJobStatusMessage(status) {
      const states = {
        COMPLETED: "Completed",
        FAILED: "Sync Failed",
        ONGOING: "Syncing...",
        PARTIAL_SUCCESS: "Partially Failed",
      };
      return states[status];
    },
    navigateToMenu(link = "") {
      this.$router.push({
        path: urlJoin(`${getCompanyBasePath()}${link}`),
      });
    },
    initializeMenuScreen() {
      let storeStatsPromise = MainService.fetchStoreStats();
      let productStatsPromise = MainService.fetchProductStats();
      Promise.all([productStatsPromise, storeStatsPromise])
        // syncPromise
        .then((resp) => {
          resp &&
            resp.forEach(({ data }, idx) => {
              let liveData = `${data.mapped}/${data.total}`;
              this.menuOptions[idx].liveCount = liveData;
            });
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
    formatLastResourceSyncDate(lastRunAt) {
      const gmtDateTime = moment.utc(
        lastRunAt,
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      let startDate = gmtDateTime.local().format("DD MMM YYYY hh:mm A");
      return startDate.slice(0, 11) + " at " + startDate.slice(11, startDate.length)
    },
    retryFPSync() {
      this.inProgress = true;
      MainService.retryFPSync().then((res) => {
        console.log(res);
        this.inProgress = false;
        this.fpSyncStatus = "ongoing"
        this.isFirstTimeOnBoard = true;
        this.innerStrokeColor = "#f2f2f2";
        this.getResourceSyncJobsInfo();
      })
    },
    closeFPSyncStatus() {
      this.isSyncStatusOn = false;
      this.isFirstTimeOnBoard = false;
      this.fpSyncStatus = "";
      localStorage.setItem("isSyncStatusOn", "no");
      localStorage.setItem("fpSyncStatus", "")
      localStorage.setItem("totalJobCompletionPercentage", "0");
      localStorage.setItem("syncStartDate", "");
    },
    checkIfFirstTimeOnboard() {
      MainService.checkIfFirstTimeOnBoard().then(({ data }) => {
        if (data.isFirstTimeOnBoard) {
          this.isFirstTimeOnBoard = true;
          this.isSyncStatusOn = true;
          this.fpSyncStatus = "ONGOING";
          MENU_OPTIONS.forEach(item => item.disabled = true);
          this.getResourceSyncJobsInfo();
        }
      });
    },
    getFpSyncStatus() {
      if (this.fpSyncList[0].lastJobStatus == "FAILED" && this.fpSyncList[1].lastJobStatus == "FAILED") {
        return "FAILED"
      } else if (this.fpSyncList[0].lastJobStatus == "COMPLETED" && this.fpSyncList[1].lastJobStatus == "COMPLETED") {
        MENU_OPTIONS.forEach(item => item.disabled = false);
        localStorage.setItem("isSyncStatusOn", "yes");
        localStorage.setItem("fpSyncStatus", "COMPLETED");
        localStorage.setItem("totalJobCompletionPercentage", "100");
        localStorage.setItem("syncStartDate", this.syncStartDate.toString())
        return "COMPLETED"
      } else if ((this.fpSyncList[0].lastJobStatus == "FAILED" && this.fpSyncList[1].lastJobStatus != "FAILED")
        || (this.fpSyncList[1].lastJobStatus == "FAILED" && this.fpSyncList[0].lastJobStatus != "FAILED")
      ) {
        return "PARTIAL_SUCCESS"
      } else {
        return "ONGOING"
      }

    },
    getResourceSyncJobsInfo(syncType = "all") {
      /***
         * get last job info for last resource sync job
         */
      this.lastJobData = {};

      let resourceSyncPromises = [];

      let syncTypeRequests = [];

      for (let sync of this.fpSyncList) {
        if (syncType != "all" && syncType != sync.value) {
          continue;
        }
        const promise = MainService.getResourceSyncLastJobInfo({}, sync.value);
        syncTypeRequests.push(sync.value);
        resourceSyncPromises.push(promise);
      }

      return Promise.allSettled(resourceSyncPromises)
        .then((resp) => {
          let pollResourceSyncStatus = {};
          syncTypeRequests.forEach((syncTypeReq, idx) => {
            if (!resp[idx].value.data) {
              return;
            }
            if (resp[idx].status == "rejected") {
              const err = resp[idx].reason;
              console.log("debug | promise rejected", err)
              return;
            }

            const syncIdx = this.fpSyncList.findIndex((fpSync) => fpSync.value == syncTypeReq);
            if (resp[idx].value.data.error_jobs?.length) {
              this.fpSyncList[syncIdx].lastJobStatus = "FAILED";
              this.innerStrokeColor = "#eb5757";
              this.fpSyncList[syncIdx].jobCompletionPercentage = 0;
              this.totalJobCompletionPercentage = 0;
            }
            else if (resp[idx].value.data.total_jobs == resp[idx].value.data.finished_jobs?.length) {
              this.fpSyncList[syncIdx].lastJobStatus = "COMPLETED";
              this.isDisabled = false;
              this.fpSyncList[syncIdx].jobCompletionPercentage = 100;
            }
            else {
              pollResourceSyncStatus[syncTypeReq] = true;
              this.fpSyncList[syncIdx].lastJobStatus = "PROGRESS";
              this.fpSyncList[syncIdx].jobCompletionPercentage = resp[idx].value.data.finished_jobs?.length / resp[idx].value.data.total_jobs * 100;
            }
            this.fpSyncList[syncIdx].lastRunAt = new Date(resp[idx].value.data.created_at);
            this.syncStartDate = this.formatLastResourceSyncDate(new Date(resp[idx].value.data.created_at));
            if (pollResourceSyncStatus[syncTypeReq]) {
              setTimeout(() => {
                this.getResourceSyncJobsInfo(syncTypeReq)
              }, 5000);
            }
          })
          this.totalJobCompletionPercentage = 0;
          this.fpSyncStatus = this.getFpSyncStatus();
          this.totalJobCompletionPercentage = Math.floor((this.fpSyncList[0].jobCompletionPercentage + this.fpSyncList[1].jobCompletionPercentage) / 2);
          this.$forceUpdate();
        })
    }
  },
  destroyed() {
    console.log("######### destroyed called");
  },
};
</script>
